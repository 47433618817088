import mainjson from './main';
import { Level } from '../utils/LoginFunctions';

function checkIf(data) {
    if (parseInt(Level()) <= 0) {
        return true;
    }
    else {
        return false;
    }
}

export default function GetData() {
        let data = {
        "apitype":"datetickets",
        "id":"date_tickettype_id",
        "options":["edit","delete","insert"],
        "nameSingle":"ticket type",
        "nameMultiple":"ticket types",
        
      
        "fields":{
            "field1":{
                "name":"Ticket Type",
                "field":"tickettype_id",
                "type":"DropDown",
                "options": "tickettypes",
                "list":true,
            }, 
            "field2":{
                "name":"Nr Available",
                "Subtype":"number",
                "field":"date_tickettype_available",
                "type":"TextInput",
                "required":true,
                "list":true,
            },
            "field3":{
                "name":"Max tickets per order",
                "Subtype":"number",
                "field":"date_tickettype_maxtickets",
                "type":"TextInput",
                "required":false,
                "list":true,
            },
            
        }
    }

    return data;
}